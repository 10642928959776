<template>
  <div class="w-content">
    <div id="FormaPassword">
      <h1>{{$t("NewKey_h1_1")}}</h1>
      <p>{{$t("NewKey_p_1")}}</p>
      <h2>{{$t("RenderKey_p_1")}}</h2>
      <p>{{$t("NewKey_h2_1")}}</p>
      <p>Account number, address (public key)</p>
      <input class="main-input" id="Public"  :placeholder="[[$t('SignIn_input_placeholder_1')]]" v-model="publicKey" />
      <p class="textColorRed" id="ErrorPub"></p>
      <p>{{$t("NewKey_label_3")}}</p>
      <input
        class="main-input"
        id="Private"
         :placeholder="[[$t('SignIn_input_placeholder_2')]]"
        v-model="privateKey"
      />
      <p class="textColorRed" id="ErrorPriv"></p>
      <p class="fontBold">
        <button class="bold-link bttn margin_top_20" id="OpenAcc" @click="continueHandler">{{$t("SignIn_button_1")}}</button>
      </p>
      <!-- <h2>Use existing key file</h2>
      <p class="fontBold">
        <a class="bold-link bttn margin_top_20">Attach Key File</a>
      </p>-->
      <p id="ErrorFileKey" class="textColorRed"></p>
      <input id="KeyFile" type="file" style="display: none;" />
    </div>
    <component :is="'Popup'" @allowSend="allowSend" v-if="displayedPopup"></component>
  </div>
</template>
<script>
import Popup from "@/components/app/popup";
import i18n from "../i18n";
export default {
  methods: {
    allowSend: function(e) {
      //listener
      console.log("allowSend!!!!");
      this.displayedPopup = false;
      let pair = nacl.sign.keyPair();
      this.privateKey = Base58.encode(pair.secretKey);
      this.publicKey = Base58.encode(pair.publicKey);

      this.$store.commit("SET_publicKey", this.publicKey);
      this.$store.commit("SET_privateKey",this.privateKey);

      let jsonData = {
        key: {
          public: this.publicKey,
          private: this.privateKey
        }
      };
      var jsonStr = JSON.stringify(jsonData);
      console.log(jsonStr);
      //TODO send json stirng on server api for response file from server
      DownloadFile("key.json", jsonStr);


      function DownloadFile(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    },
    continueHandler: function() {
      this.$router.push(`/${i18n.locale}/wallet/perform-transaction`);
    }
  },
  created() {
    //console.log("component was created! REGISTER");
  },
  mount() {},

  data: function() {
    return {
      privateKey: "",
      publicKey: "",
      displayedPopup: true
    };
  },
  components: {
    Popup
  }
};
</script>
